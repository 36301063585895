import { useEffect, useState } from "react";
import anime from "animejs/lib/anime.es.js";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../store/modules/auth/actions";

const Menu = ({ visible }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const username = useSelector((state) => state.auth.user.name);
  const [profilePicture, setProfilePicture] = useState(useSelector(state => state.auth.user.profilePicture));

  useEffect(() => {
    anime({
      targets: ".dir-reverse",
      translateX: 250,
      direction: "reverse",
      easing: "easeInOutSine",
      duration: 100,
    });
  }, [visible]);

  useEffect(() => {
    if (profilePicture) return;
    setProfilePicture('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==')
  }, []);

  function handleClickLogOut(e) {
    dispatch(authActions.loginFailure());
  }

  return (
    visible && (
      <div className="dir-reverse font-Poppins fixed bg-Fundo h-full top-0 right-0 w-52 z-50 shadow-lg transition-transform duration-300 sm:w-60 ">
        <h1 className="text-white font-extrabold text-3xl pt-11 px-6 pb-4 sm:text-4xl">
          Menu
        </h1>
        <div className="mx-6">
          <hr className="w-40 border-1 border-CinzaClaro sm:w-52" />
          <ul className=" text-white flex flex-col my-20 text-lg cursor-pointer ">
            <Link to="/">
              <li className="my-2 hover:border-b-2 w-max  pointer ">Inicio</li>
            </Link>
            <Link to="/myworkouts">
              <li className="my-2 hover:border-b-2 w-max">Treinos</li>
            </Link>
            <Link to="/createworkout">
              <li className="my-2 hover:border-b-2 w-max h-max">Novo treino</li>
            </Link>
            <Link to="/profile">
              <li className="my-2 hover:border-b-2 w-max h-max">Perfil</li>
            </Link>
            <a
              href="https://forms.gle/NbXzoaht4nmAuUy37"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="my-2 hover:border-b-2 w-max h-max">
                Avalie o sistema
              </li>
            </a>
          </ul>
          <hr className="w-40  border-1 border-CinzaClaro sm:w-52" />
          {isLoggedIn ? (
            <div className="mt-10">
              <p className="text-white text-md mb-2 flex flex-col">
                Bem-vindo(a)
              </p>
              <div className="flex flex-row items-center">
                <img
                  src={profilePicture}
                  className="bg-black w-10 h-10 mb-4 rounded-full border-2 border-IconsColor mr-3"
                />
                <p className="text-white text-md mb-4 flex flex-col">
                  {username}
                </p>
              </div>
              <Link to="/login">
                <button
                  className="effectButtons text-red-700 cursor-pointer font-black text-xl"
                  onClick={(e) => handleClickLogOut(e)}
                >
                  Sair
                </button>
              </Link>
            </div>
          ) : (
            <Link to="/login">
              <button className="effectButtons text-Laranja hover:text-orange-700 active:text-orange-800 cursor-pointer font-blank mt-10 text-xl">
                Entrar
              </button>
            </Link>
          )}
        </div>
      </div>
    )
  );
};

Menu.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Menu;
