const BtnBlue = ({ name, save }) => {

  const handleSaveChanges = () => {
    save();
  }

    return (
      <button className="bg-blue-800 px-7 py-2 m-2 font-Poppins font-extrabold text-white text-lg rounded-sm shadow-shadow-main-black active:bg-black active:shadow-shadow-main-black active:text-IconsColor effect" onClick={handleSaveChanges}>
        <p className="tracking-wider">{name}</p>
      </button>
    );
  };
  
  export default BtnBlue;