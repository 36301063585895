import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../store/modules/auth/actions";
import Loading from "../components/Loading/index";
import NavBar from "../components/NavBar/NavBar";
import RecoverHeader from "../components/Header/ConfirmCodeHeader";
import { toast } from "react-toastify";
import BtnOrange from "../components/GlobalsButtons/BtnOrange";

const RecoverPassword = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const pastPage = useSelector((state) => state.auth.pastPage);
  const [email, setEmail] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCLickSendCode = (e) => {
    e.preventDefault();

    if (email === "") return toast.error("Email não pode estar vazio");

    dispatch(authActions.sendRecoveryCode({ email }));
  };

  return (
    <>
      <NavBar />
      <div className="container-reponsive">
        <Loading isLoading={isLoading} />
        <RecoverHeader />
        <form className="flex flex-col font-Poppins mt-9">
          <label htmlFor="email" className="mx-16 mb-1">
            <p className="font-Poppins text-white text-lg">Email:</p>
            <input
              type="email"
              name="Email"
              placeholder="Example@gmail.com"
              required
              className="inputs-fullScreen mb-5"
              onChange={handleChangeEmail}
              value={email}
            />
            <div className="flex justify-center items-center mt-14">
              {/* Botão para enviar o login */}
              <BtnOrange
              name={"Enviar código"}
              next={(e) => handleCLickSendCode(e)}
               />
            </div>
          </label>
        </form>
      </div>
    </>
  );
};

export default RecoverPassword;
