import { useState, useEffect } from "react";

// Components
import NavBar from "../components/NavBar/NavBar";
import Modal from "../components/Modal/Modal";
import ModalSelection from "../components/Modal/ModalSelection";
import NewExercise from "../components/NewExercise/NewExercise";
import DeleteWorkoutAlert from "../components/Alert/DeleteWorkout"; // Import Alert
import DeleteExerciseAlert from "../components/Alert/DeleteExercise"; // Import Alert

// import UnsavedChanges from "../components/Alert/UnsavedChanges";

// icons
import { FaRegPenToSquare } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";
import { AiOutlineEllipsis } from "react-icons/ai";
import { IoIosFitness } from "react-icons/io";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../services/axios";
import * as workoutActions from "../store/modules/workout/actions";
import { toast } from "react-toastify";
import history from "../services/history";
import { TiPlus } from "react-icons/ti";

const Training = () => {
  const dispatch = useDispatch();
  const editingExerciseIndex = useSelector(
    (state) => state.workout.editingExerciseIndex
  );
  const { id: workoutId } = useParams();
  const [workout, setWorkout] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [days, setDays] = useState([]);
  const [editing, setEditing] = useState(false);
  const [btnNewExercise, setBtnNewExercise] = useState(false);
  const [isNewExercise, setIsNewExercise] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSelectionOpen, setIsModalSelectionOpen] = useState(false);
  const [deleteWorkoutAlert, setDeleteWorkoutAlert] = useState(false); // Novo estado para Alert
  const [deleteExerciseAlert, setDeleteExerciseAlert] = useState(false); // Novo estado para Alert
  const [indexExerciseToDelete, setIndexExerciseToDelete] = useState(false);

  // const [isUnsavedChangesOpen, setIsUnsavedChangesOpen] = useState(false);


  // Funções de deleteWorkoutAlerta
  const handleClickDeleteWorkoutAlert = () => setDeleteWorkoutAlert(true);
  const handleCloseDeleteWorkoutAlert = async (deleteWorkout) => {
    setDeleteWorkoutAlert(false);

    if (!deleteWorkout) return;

    try {
      const data = await axios.delete(`/workouts/${workoutId}`);
      toast.success("Treino excluído");
      history.push("/myworkouts");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDeleteExercise = (index) => {
    setDeleteExerciseAlert(true);
    setIndexExerciseToDelete(index);
  };

  const handleCloseDeleteExerciseAlert = (deleteExercise) => {
    setDeleteExerciseAlert(false);

    if (!deleteExercise) return;

    exercises.splice(indexExerciseToDelete, 1);
    setIndexExerciseToDelete(false);
  };

  const handleOpenModalSelection = (e) => {
    setIsModalSelectionOpen(true);
  };
  const handleCloseModalSelection = () => {
    if (isModalSelectionOpen === true) {
      setIsModalSelectionOpen(false);
    }
  };

  // const handleOpenUnsavedChanges = (e) => {
  //   setIsUnsavedChangesOpen(true);
  // };
  // // const handleCloseUnsavedChanges = () => {
  // //   if (isUnsavedChangesOpen === true) {
  // //     setIsUnsavedChangesOpen(false);
  // //   }
  // // };

  useEffect(() => {
    if (isModalSelectionOpen) {
      // Adiciona a classe ao body quando o modal está aberto
      document.body.style.overflow = "hidden";
    } else {
      // Remove a classe do body quando o modal está fechado
      document.body.style.overflow = "auto";
    }

    // Limpeza quando o componente for desmontado
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalSelectionOpen]);

  const handleOpenModal = (exerciseIndex) => {
    dispatch(
      workoutActions.setCurrentEditingExercise({
        exercise: exercises[exerciseIndex],
        exerciseIndex: exerciseIndex,
      })
    );
    setIsModalOpen(true);
  };

  const handleCloseModal = (newExercise) => {
    setIsModalOpen(false);

    if (!newExercise) return;

    setExercises((prevExercises) =>
      prevExercises.map((exercise, index) =>
        index === editingExerciseIndex ? newExercise : exercise
      )
    );
  };

  useEffect(() => {
    function getData() {
      axios
        .get(`/workouts/${workoutId}`)
        .then((response) => {
          const data = response.data;
          setWorkout(data);
          setExercises(data.exercises);
          handleDays(data.days)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getData();
  }, []);

  const handleDays = (wDays) => {
    wDays.forEach(day => {
      if (day === 0) days.push('Dom');
      if (day === 1) days.push('Seg');
      if (day === 2) days.push('Ter');
      if (day === 3) days.push('Qua');
      if (day === 4) days.push('Qui');
      if (day === 5) days.push('Sex');
      if (day === 6) days.push('Sáb'); 
    })
  }

  useEffect(() => {
    if (exercises.length > 0) {
    }
  }, [exercises]);

  const handleClickEdit = (e) => {
    setBtnNewExercise(true);
    setEditing(true);
  };

  const handleClickNewExercise = (e) => {
    dispatch(workoutActions.clickedNewExercise())
    setIsNewExercise(true);
  };

  const handleCloseNewExercise = (newExercise) => {
    setIsNewExercise(false);

    if (!newExercise) return;

    exercises.push(newExercise);
  };

  const handleClickSaveChanges = async (e) => {
    setBtnNewExercise(false);
    setEditing(false);
    workout.exercises = exercises;

    try {
      const data = await axios.put(`/workouts/${workoutId}`, workout);
      toast.success("Alterações salvas com sucesso");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCancelChanges = async (e) => {
    setEditing(false);
    setBtnNewExercise(false);
  };

  const handleClickConcludeWorkout = async () => {
    workout.status = "Done";
    try {
      const data = await axios.put(`/workouts/${workoutId}`, {
        status: "Done",
      });
      toast.success("Alterações salvas com sucesso");
      history.push("/myworkouts");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <NavBar />
      <div className=" md:px-20 lg:px-60" onClick={handleCloseModalSelection}>
        <div className="font-Poppins flex flex-row items-center justify-between my-8 mx-8">
          <h1 className="text-white text-3xl font-bold">{workout.name}</h1>
          {editing === false ? (
            <div className="flex flex-col items-center relative"> {/* Adiciona relative aqui */}
            <div className="mb-3">
              <AiOutlineEllipsis
                size={70}
                className="cursor-pointer text-IconsColor"
                onClick={(e) => handleOpenModalSelection(e)}
              />
            </div>
            {isModalSelectionOpen && (
              <div className="absolute top-full -mt-7 z-10"> 
                <ModalSelection
                  isOpen={isModalSelectionOpen}
                  onEdit={handleClickEdit}
                  onDelete={handleClickDeleteWorkoutAlert}
                />
              </div>
            )}
          </div>
          ) : (
            <button
              lang="pt-br"
              onClick={handleClickNewExercise}
              className=" effectButtons bg-Laranja hover:bg-orange-700 focus:bg-orange-800 text-white text-xl font-bold flex flex-row  items-center px-3 py-2 rounded "
            >
              <TiPlus size={24} className="mr-0.5 -ml-1" />
              <p>Exercício</p>
            </button>
          )}
        </div>
        <div className="font-Poppins text-white mt-8 mx-8 flex flex-row items-center justify-between">
          <p className="text-lg font-extralight text-IconsColor">
            {workout.type === 'unique' ? new Date(workout.date + "T00:00:00").toLocaleDateString("pt-BR")
            : days  
          }
          </p>
        </div>
        <div className="mx-8 my-2">
          <hr className="w-full bg-CinzaClaro" />
        </div>

        {exercises
          ? exercises.map((exercise, exerciseIndex) => (
              <div key={exerciseIndex}>
                <div className="mx-8 font-Poppins flex flex-row justify-between">
                  <div className="flex flex-col">
                    {editing === true ? (
                      <FaRegPenToSquare
                        size={20}
                        className="cursor-pointer text-IconsColor hover:text-gray-600 focus:text-gray-800 my-2"
                        onClick={(e) => handleOpenModal(exerciseIndex)}
                      />
                    ) : (
                      ""
                    )}

                    <p className="font-Poppins text-white text-lg mb-0">
                      {exercise.name}
                    </p>
                    <p className="font-Poppins text-Laranja text-lg">
                      {exercise.muscle}
                    </p>

                    {editing === true ? (
                      <FaTrash
                        size={24}
                        className="cursor-pointer text-red-700 hover:text-red-800 focus:text-red-900 my-2"
                        onClick={(e) =>
                          handleClickDeleteExercise(exerciseIndex)
                        } // Clique no Trash abre o Alert
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-white">
                    <table>
                      <thead className="font-extralight">
                        <tr>
                          <th className="px-2 p-1">Série</th>
                          <th className="px-2 p-1">Reps</th>
                          <th className="px-2 p-1">Peso</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exercise.series
                          ? exercise.series.map((serie, serieIndex) => (
                              <tr key={serieIndex} className="text-center">
                                <td>{serie.serie}</td>
                                <td>{serie.repetitions}</td>
                                <td>{serie.weight}</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mx-8">
                  <hr className="w-full bg-CinzaClaro my-1" />
                </div>
              </div>
            ))
          : ""}

        {workout.status == "Done" ? (
          editing === true ? (
            <div>
              <div className="flex items-center justify-center my-6">
                <button
                  className=" effectButtons rounded-lg font-bold text-white text-xl font-Poppins px-6 py-2 bg-blue-700 focus:bg-blue-500 "
                  onClick={(e) => handleClickSaveChanges(e)}
                >
                  Salvar alterações
                </button>
              </div>

              <div className="flex items-center justify-center my-6">
                <button
                  className=" effectButtons rounded-lg font-bold text-white text-xl font-Poppins px-6 py-2 bg-gray-500 focus:bg-blue-500 "
                  onClick={(e) => handleClickCancelChanges(e)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          ) : (
            ""
          )
        ) : editing === true ? (
          <div>
            <div className="flex items-center justify-center my-6">
              <button
                className=" effectButtons rounded-lg font-bold text-white text-xl font-Poppins px-6 py-2 bg-blue-700 hover:bg-blue-800 focus:bg-gray-800 "
                onClick={(e) => handleClickSaveChanges(e)}
              >
                Salvar alterações
              </button>
            </div>

            <div className="flex items-center justify-center my-6">
              <button
                className=" effectButtons rounded-lg font-bold text-white text-xl font-Poppins px-6 py-2 bg-gray-500 focus:bg-gray-800 "
                onClick={(e) => handleClickCancelChanges(e)}
              >
                Cancelar
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center my-6">
            <button
              className=" effectButtons rounded-lg font-bold text-white text-xl font-Poppins px-6 py-2 bg-Laranja hover:bg-orange-700 focus:bg-orange-900 "
              onClick={handleClickConcludeWorkout}
            >
              Concluir Treino
            </button>
            {/* Botão pra testar o alert UnsavedChanges
            <AiOutlineEllipsis
                size={70}
                className="cursor-pointer text-IconsColor"
                onClick={(e) => handleOpenUnsavedChanges(e)}
              />
              {isUnsavedChangesOpen && (
              <div className="absolute m-auto z-11"> 
                <UnsavedChanges
                  isOpen={isUnsavedChangesOpen}
                />
              </div>
            )} */}
          </div>
        )}
        <Modal
          isOpen={isModalOpen}
          onClose={(newExercise = false) => handleCloseModal(newExercise)}
        />
        <NewExercise
          isOpen={isNewExercise}
          onClose={(newExercise = false) => handleCloseNewExercise(newExercise)}
        />
      </div>
      <DeleteWorkoutAlert
        isOpen={deleteWorkoutAlert}
        onClose={(deleteWorkout) =>
          handleCloseDeleteWorkoutAlert(deleteWorkout)
        }
      />
      <DeleteExerciseAlert
        isOpen={deleteExerciseAlert}
        onClose={(deleteExercise) =>
          handleCloseDeleteExerciseAlert(deleteExercise)
        }
      />
    </>
  );
};

export default Training;
