import { FaXmark } from "react-icons/fa6";
import ProfileGrid from "../../images/profilePictures/index";
import BtnBlue from '../GlobalsButtons/BtnBlue';
import { useDispatch, useSelector } from "react-redux";
import axios from '../../services/axios';
import { toast } from "react-toastify";
import * as authActions from '../../store/modules/auth/actions';


const SelectAvatar = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const newProfilePicture = useSelector(state => state.auth.newProfilePicture);
  const profilePicture = useSelector(state => state.auth.user.profilePicture);
  const userId = useSelector(state => state.auth.user.id);


  if (!isOpen) return null;
  const handleClickClose = () => {
    onClose();
  }

  const save = async () => {
    if (!newProfilePicture) return toast.error('Selecione um avatar');
    if (profilePicture === newProfilePicture) return toast.error('Avatar já está sendo utilizado')
    try {
      await axios.put(`/users/${userId}`, {profilePicture: newProfilePicture});
      dispatch(authActions.updateUser({profilePicture: newProfilePicture}))
      toast.success('Avatar atualizado com sucesso')
      onClose(newProfilePicture); 
    } catch (err) {
      toast.error('Erro ao atualizar imagem')
      console.log(err)
    }
  }


  return (
    <div className="modal-overlay ">
      <div className="modal-content">
        <div className="flex w-full items-center justify-center relative mb-6">
          <h1 className="font-Poppins text-lg font-bold text-white">
            Escolha o avatar
          </h1>
          <FaXmark
            size={35}
            className="text-IconsColor absolute right-0 cursor-pointer"
            onClick={handleClickClose}
          />

        </div>
       <div className="scrollbar-custom h-96 max-h-96 overflow-y-scroll">
        <ProfileGrid />
       </div>
       <BtnBlue name='Salvar' save={save}  />
      </div>
    </div>
  );
};

export default SelectAvatar;
