// Components
import NavBar from "../components/NavBar/NavBar";
import { toast } from "react-toastify";
import { isEmail } from "validator";
import BtnOrange from "../components/GlobalsButtons/BtnOrange";
//Route
import { useDispatch, useSelector } from "react-redux";
//Hooks
import { useState, useRef } from "react";

import { get } from "loadsh";

import * as actionsAuth from "../store/modules/auth/actions";
// Route
import { Link } from "react-router-dom";

// Material-UI
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";
import Loading from "../components/Loading/index";

const Login = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const prevPath = get(props, "location.state.prevPath", "/");

  //Estados dos inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSenha, setShowSenha] = useState(false);

  // Referência para o input de senha
  const passwordInputRef = useRef(null);

  // Alterna a visibilidade da senha
  const toggleSenhaVisibility = () => {
    setShowSenha((prevShowSenha) => !prevShowSenha);
    passwordInputRef.current.blur(); // Remove o foco do input
  };

  // Pegga os dados dos inputs
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail();
    setPassword();

    let formErrors = false;

    if (!isEmail(email)) {
      formErrors = true;
      toast.error("Email inválido");
    }

    if (password.length < 8 || password.length > 50) {
      formErrors = true;
      toast.error("Senha deve ter entre 8 e e 30 caracteres");
    }

    if (formErrors) return;

    dispatch(actionsAuth.loginRequest({ email, password, prevPath }));
  };

  return (
    <>
      <NavBar />
      <div className="container-reponsive flex flex-col justify-between h-full">
      <Loading isLoading={isLoading} />
        <form onSubmit={handleSubmit} className="mx-11 flex-1 mt-6">
          <h1 className="Title-pages text-2xl mt-0 mb-10">
            Entre em sua conta
          </h1>
          {/* Email */}
          <label htmlFor="Email">
            <span className="font-Poppins text-white text-lg">Email:</span>
            <input
              type="email"
              name="Email"
              placeholder="Example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="inputs-fullScreen mb-5"
            />
          </label>

          {/* Senha */}
          <label htmlFor="password">
            <span className="font-Poppins text-white text-lg">Senha:</span>
            <div className="relative text-white">
              <Input
                type={showSenha ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                inputRef={passwordInputRef}
                className="inputs-fullScreen text-white px-2 py-1"
                disableUnderline
                sx={{
                  input: { color: "white" },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleSenhaVisibility}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showSenha ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </label>

          {/* Link para recuperar senha */}
          <Link to="/recoverpassword">
            <p className="mt-0.5 text-blue-400 hover:text-blue-500 active:text-blue-950 pointer"
              onClick={() => dispatch(actionsAuth.setPastPage('login'))}
            >
              Esqueci a senha
            </p>
          </Link>
          <div className="flex justify-center items-center mt-12">
            {/* Botão para enviar o login */}
            <BtnOrange name="Entrar" type="submit" />
          </div>
        </form>
         {/* Link para criar conta */}
         <Link to="/createaccount">
            <p className="text-white text-center mb-6 mt-5">
              Não tem uma conta?{" "}
              <strong className="text-Laranja">Criar conta</strong>
            </p>
          </Link>
      </div>
    </>
  );
};

export default Login;
