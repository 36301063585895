import React  from "react";
import { Container } from "./styled";
import PropagateLoader from "react-spinners/PropagateLoader";


export default function Loading({ isLoading }) {
    if (!isLoading) return <></>;
    return (
        <Container>
            <div></div>
            <PropagateLoader color="#617073" />
        </Container>
    )
}

Loading.defaultProps = {
    isLoading: false
}

