/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { FaXmark } from "react-icons/fa6";
import { TiPlus } from "react-icons/ti";
import { IoMdRemove } from "react-icons/io";

const NewExercise = ({ isOpen, onClose }) => {
  const clicksNewExercise = useSelector(state => state.workout.timesClickedNewExercise);
  const [exercise, setExercise] = useState(
    {
      name: '',
      muscle: '',
      series: [{
        serie: 1,
        repetitions: '',
        weight: ''
      }]
    }


  );


  useEffect(() => {
    setExercise({
      name: '',
      muscle: '',
      series: [{
        serie: 1,
        repetitions: '',
        weight: ''
      }]
    })
  }, [clicksNewExercise]);



  const handleChangeInputMuscle = (e) => {
    exercise.muscle = e.target.value;
  };

  const handleChangeInputExercise = (e) => {
    exercise.name = e.target.value;
  }

  const handleClickAddSerie = (e) => {
    e.preventDefault();
    const newSerie = {
      serie: exercise.series.length + 1,
      repetitions: "",
      weight: "",
    };

    setExercise((prevExercise) => ({
      ...prevExercise,
      series: [...prevExercise.series, newSerie],
    }));
  };

  const handleClickRemoveSerie = (e) => {
    e.preventDefault();
    if (exercise.series.length === 1)
      return toast.error("Exercício precisa ter pelo menos uma série");

    setExercise((prevExercise) => ({
      ...prevExercise,
      series: prevExercise.series.slice(0, -1),
    }));
  };

  const handleChangeInputsSeries = (index, field, value) => {
    setExercise((prevExercise) => {
      const updatedSeries = prevExercise.series.map((serie, i) => {
        if (i === index) {
          return { ...serie, [field]: Number(value) };
        }
        return serie;
      });
      return { ...prevExercise, series: updatedSeries };
    });
  };

  const handleLeaveInputSeries = (index, field, value) => {
    if (value > 0) return;
    toast.error("Valor precisa ser maior ou igual a 1");
    setExercise((prevExercise) => {
      const updatedSeries = prevExercise.series.map((serie, i) => {
        if (i === index) {
          return { ...serie, [field]: Number(1) };
        }
        return serie;
      });
      return { ...prevExercise, series: updatedSeries };
    });
  };

  const handleClickSave = (e) => {
    e.preventDefault()
    let errorsSeries = false; 

    if (exercise.exercise === '' || exercise.muscle === '') return toast.error('Preencha os campos de muscúlo e de exercício')

    exercise.series.map((serie, i) => {
      if (serie.repetitions <= 0 || serie.weight <= 0) errorsSeries = true;
    });

    if (errorsSeries) return toast.error(
      "Valores dos campos de séries precisam ser maior ou igual a 1"
    );

    toast.success('Alterações salvas com sucesso');
    onClose(exercise);
  };

  const handleExitNewExercise = () => {
    setExercise({
      name: '',
      muscle: '',
      series: [{
        serie: 1,
        repetitions: '',
        weight: ''
      }]
    })
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="flex w-full items-center justify-center relative mb-16">
          <h1 className="font-Poppins text-2xl font-bold text-white">
            Novo exercício
          </h1>
          <FaXmark
            size={35}
            onClick={handleExitNewExercise}
            className="text-IconsColor absolute right-0 cursor-pointer"
          />
        </div>
        <form className=" text-xl font-Poppins text-IconsColor ">
          <label>
            <span>Musculo:</span>
            <input type="text" name="muscle" id="muscle"
              className=" inputs-fullScreen"
              onChange={e => handleChangeInputMuscle(e)}
            />
          </label>
          <label>
            <span>Exercício:</span>
            <input type="text" name="exercise" id="exercise"
              className=" inputs-fullScreen"
              onChange={e => handleChangeInputExercise(e)}
            />
          </label>
          <div className="flex justify-center mt-4 overflow-y-scroll max-h-56 scrollbar-custom w-full px-2">
            <table>
              <thead className="font-extralight">
                <tr>
                  <th className="text-center font-Poppins text-IconsColor">
                    Série
                  </th>
                  <th className="text-center font-Poppins text-IconsColor">
                    Reps
                  </th>
                  <th className="text-center font-Poppins text-IconsColor">
                    Peso
                  </th>
                </tr>
              </thead>
              <tbody className="max-h-60 overflow-y-scroll">
                {exercise.series.map((serie, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.serie}
                        readOnly
                        className="inputs-menores text-center w-16"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.repetitions}
                        onChange={(e) =>
                          handleChangeInputsSeries(
                            index,
                            "repetitions",
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          handleLeaveInputSeries(index, "repetitions", e.target.value)
                        }
                        className="inputs-menores text-center w-16 "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputMode="numeric"
                        value={serie.weight}
                        onChange={(e) =>
                          handleChangeInputsSeries(index, "weight", e.target.value)
                        }
                        onBlur={(e) =>
                          handleLeaveInputSeries(index, "weight", e.target.value)
                        }
                        className="inputs-menores text-center w-16 "
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className=" flex flex-row justify-between px-10 ">
            <button
              className="effectButtons flex flex-row cursor-pointer font-Poppins font-bold text-blue-600 hover:text-blue-700 active:text-blue-900 mt-5"
              onClick={handleClickAddSerie}
            >
              <TiPlus size={24} className="mr-0.5 -ml-1" />
              Serie
            </button>

            <button
              className="effectButtons flex flex-row items-center cursosr-pointer font-Poppins font-bold text-red-600 mt-5 hover:text-red-700 active:text-red-900"
              onClick={handleClickRemoveSerie}
            >
              <IoMdRemove size={24} className="mr-0.5 -ml-1" />
              Serie
            </button>
          </div>
          <div className="flex justify-center">
            <button
              className="effectButtons  cursor-pointer bg-Laranja hover:bg-orange-700 active:bg-orange-800 py-2 px-11 text-xl text-white font-bold font-Poppins rounded-lg mt-4"
              onClick={handleClickSave}
            >
             Salvar 
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewExercise;
