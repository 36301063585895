import * as types from '../types' 

export function loginRequest(payload) {
    return {
        type: types.LOGIN_REQUEST,
        payload
    }
}

export function loginSuccess(payload) {
    return {
        type: types.LOGIN_SUCCESS,
        payload
    }
}

export function loginFailure(payload) {
    return {
        type: types.LOGIN_FAILURE,
        payload
    }
}

export function setPastPage(payload) {
    return {
        type: types.SET_PAST_PAGE,
        payload
    }
}

export function setNewProfilePicture(payload) {
    return {
        type: types.SET_NEW_PROFILE_PICTURE,
        payload
    }
}

export function updateUser(payload) {
    return {
        type: types.UPDATE_USER,
        payload
    }
}





export function registerRequest(payload) {
    return {
        type: types.REGISTER_REQUEST,
        payload
    }
}

export function registerUpdatedSuccess(payload) {
    return {
        type: types.REGISTER_UPDATED_SUCCESS,
        payload
    }
}

export function registerCreateddSuccess(payload) {
    return {
        type: types.REGISTER_CREATED_SUCCESS,
        payload
    }
}

export function registerFailure(payload) {
    return {
        type: types.REGISTER_FAILURE,
        payload
    }
};

export function verifyRegisterCodeRequest(payload) {
    return {
        type: types.VERIFY_REGISTER_CODE_REQUEST,
        payload
    }
}

export function verifyRecoveryCodeRequest(payload) {
    return {
        type: types.VERIFY_RECOVERY_CODE_REQUEST,
        payload
    }
}

export function verifyRecoveryCodeSuccess(payload) {
    return {
        type: types.VERIFY_RECOVERY_CODE_SUCCESS,
        payload
    }
}

export function verifyRecoveryCodeFailure(payload) {
    return {
        type: types.VERIFY_RECOVERY_CODE_FAILURE,
        payload
    }
}

export function passwordUpdateRequest(payload) {
    return {
        type: types.PASSWORD_UDDATE_REQUEST,
        payload
    }
}

export function passwordUpdatedSuccess(payload) {
    return {
        type: types.PASSWORD_UDDATED_SUCCESS,
        payload
    }
}

export function passwordUpdatedFailure(payload) {
    return {
        type: types.PASSWORD_UDDATED_FAILURE,
        payload
    }
}




export function resentRegisterCode(payload) {
    return {
        type: types.RESENT_REGISTER_CODE,
        payload
    }
}

export function sendRecoveryCode(payload) {
    return {
        type: types.SEND_RECOVERY_CODE,
        payload
    }
}

export function sendCodeSuccess(payload) {
    return {
        type: types.SEND_CODE_SUCCESS,
        payload
    }
}

export function sendCodeFailure(payload) {
    return {
        type: types.SEND_CODE_FAILURE,
        payload
    }
}







