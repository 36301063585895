import React from "react";
import { Switch } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { toast } from "react-toastify";

// Pages 
import Home from "../pages/Homee"
import CreateWorkout from '../pages/CreateWorkout'; 
import MyWorkouts from "../pages/MyWorkouts";
import Login from "../pages/Login";
import CreateAccount from "../pages/CreateAccount";
import Workout from "../pages/Workout";
import Error404 from "../pages/Error404";
import RecoverPassword from "../pages/RecoverPassword";
import ConfirmEmail from "../pages/ConfirmEmail";
import NewPassword from "../pages/NewPassword";
import MyRoute from "./MyRoute";
import Profile from "../pages/Profile";



export default function Routes() {
    return (
        <Switch>
            <MyRoute exact path='/' component={Home}  />
            <MyRoute exact path='/createworkout' component={CreateWorkout} isClosed={true} />
            <MyRoute exact path='/myworkouts' component={MyWorkouts} isClosed={true} />
            <MyRoute exact path='/login' component={Login} />
            <MyRoute exact path='/createaccount' component={CreateAccount} />
            <MyRoute exact path='/workout/:id' component={Workout} isClosed={true} />
            <MyRoute exact path='/recoverpassword' component={RecoverPassword} />
            <MyRoute exact path='/confirmemail' component={ConfirmEmail} />
            <MyRoute exact path='/newpassword' component={NewPassword} />
            <MyRoute exact path="/profile" component={Profile} isClosed={true} />
            <MyRoute path='*' component={Error404} />
        </Switch>

    );
};