import { useState, useRef } from "react";
import NavBar from "../components/NavBar/NavBar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../store/modules/auth/actions";
import { toast } from "react-toastify";
import Loading from "../components/Loading/index";
import { Link } from "react-router-dom";
import BtnOrange from "../components/GlobalsButtons/BtnOrange";

const CriarConta = () => {
  const id = useSelector((state) => state.auth.user.id);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [nomeError, setNomeError] = useState("");
  const [sobreNomeError, setSobrenomeError] = useState("");
  const [senhaError, setSenhaError] = useState("");
  const [showSenha, setShowSenha] = useState(false);

  const passwordInputRef = useRef(null);

  const toggleSenhaVisibility = () => {
    setShowSenha((prevShowSenha) => !prevShowSenha);
    setTimeout(() => {
      passwordInputRef.current.blur(); // Remove o foco do input
    });
  };

  const handleNome = (e) => {
    setName(e.target.value);
    if (e.target.value.length >= 25) {
      setNomeError("Seu nome é muito longo");
    } else {
      setNomeError("");
    }
  };

  const handleSobreNome = (e) => {
    setLastname(e.target.value);
    if (e.target.value.length >= 50) {
      setSobrenomeError("Seu sobrenome é muito longo");
    } else {
      setSobrenomeError("");
    }
  };

  const handleSenha = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length < 8) {
      setSenhaError("Sua senha deve ter pelo menos 8 caracteres");
    } else {
      setSenhaError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.length < 2 || name.length > 25)
      return toast.error("Nome deve ter entre 2 e 25 caracteres");
    if (lastname.length <= 2 || lastname.length > 50)
      return toast.error("Sobrenome deve ter entre 3 e 50 caracteres");
    if (password.length < 8 || password.length > 30)
      return toast.error("Senha deve ter entre 8 e 30 caracteres");

    if (nomeError || sobreNomeError || senhaError) return;
    dispatch(authActions.setPastPage('createAccount'))
    dispatch(
      authActions.registerRequest({ name, lastname, email, password, id })
    );
  };

  return (
    <>
      <NavBar />
      <Loading isLoading={isLoading} />
      <div className="container-reponsive flex flex-col justify-between h-full">
        <div className="flex flex-col items-center">
          <h1 className="Title-pages mt-5">Inscreva-se no</h1>
          <p className="font-Poppins font-extralight text-white text-3xl">
            Training log
          </p>
        </div>
        <div className="flex flex-col mx-11 my-0">
          <form onSubmit={handleSubmit}>
            <label htmlFor="Name" className="flex flex-col">
              <span className="text-white text-lg">Nome:</span>
              <input
                type="text"
                name="Name"
                placeholder="Digite seu nome"
                value={name}
                onChange={handleNome}
                id="name"
                required
                className="inputs-fullScreen" />
              <p className="text-red-600 text-base">{nomeError}</p>
            </label>

            {/* Sobrenome */}
            <label htmlFor="Sobrenome" className="flex flex-col">
              <span className="text-white text-lg">Sobrenome:</span>
              <input
                type="text"
                name="Sobrenome"
                placeholder="Digite seu sobrenome"
                value={lastname}
                onChange={handleSobreNome}
                id="lastname"
                required
                className="inputs-fullScreen mt-1 mb-2"
              />
              <p className="text-red-600 text-base">{sobreNomeError}</p>
            </label>

            <label htmlFor="Email" className="flex flex-col">
              <span className="text-white text-lg">Email:</span>
              <input
                type="email"
                name="Email"
                placeholder="Example@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                required
                className="inputs-fullScreen mt-1 mb-2"
              />
            </label>

            <label htmlFor="password" className="flex flex-col">
              <span className="text-white text-lg">Senha:</span>
              <div className="relative text-white">
                <Input
                  type={showSenha ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={handleSenha}
                  id="password"
                  required
                  inputRef={passwordInputRef}
                  className="inputs-fullScreen text-white px-2 py-1"
                  disableUnderline
                  sx={{
                    input: { color: "white" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleSenhaVisibility}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showSenha ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <p className="text-red-600 text-base">{senhaError}</p>
            </label>

            <div className="flex justify-center items-center mt-11 md:mt-11">
              <BtnOrange
              type="submit"
              name={"Criar conta"} />
            </div>
          </form>
          <Link to="/login">
            <p className="text-white text-center mb-6 mt-5">Já tem uma conta? <strong className="text-Laranja">Fazer login</strong></p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CriarConta;
