import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from '../../services/axios';

import * as workoutActions from '../../store/modules/workout/actions';


import Title from "./Title/Title";
import BtnOrange from "../GlobalsButtons/BtnOrange";
import BtnGrey from "../GlobalsButtons/BtnGrey";
import { toast } from "react-toastify";

const Week = ({ next, previous }) => {
  const dispatch = useDispatch();
  const creatingWorkout = useSelector(state => state.workout.creatingWorkout);
  const userId = useSelector(state => state.auth.user.id);

  const [selectedDays, setSelectedDays] = useState([]);
  const [date, setDate] = useState(null);
  const [workout, setWorkout] = useState('');
  const [daysUnavailable, setDaysUnavailable] = useState([]);
  

  useEffect( () => {

    async function getData() {
      try {
        const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
        setWorkout(dataApi.data);
        dataApi.data.forEach((workout) => {
          for (let c = 0; c < workout.days.length; c++) {
            if (workout.days[c] === 'none') return;
            if (daysUnavailable.includes(workout.days[c])) return
            daysUnavailable.push(workout.days[c]);
          }
          
        })
      } catch (err) {
        console.log(err)
      }
    }

    getData();

  }, []);
  

  const daysOfWeek = [
    { text: "D", day: 0, label: "Domingo" },
    { text: "S", day: 1, label: "Segunda" },
    { text: "T", day: 2, label: "Terça" },
    { text: "Q", day: 3, label: "Quarta" },
    { text: "Q", day: 4, label: "Quinta" },
    { text: "S", day: 5, label: "Sexta" },
    { text: "S", day: 6, label: "Sábado" },
  ];

  const toggleDaySelection = (day) => {
    if (daysUnavailable.includes(day)) return toast.error('Já existe um treino fixo neste dia');

    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleChangeDate = (e) => {
    setDate(e.target.value);
    console.log(e.target.value)
  }

  const handleLeaveComponent = (e, btnType) => {
    console.log(creatingWorkout, 'babaa')
    if (creatingWorkout.type === 'unique') {
      if (creatingWorkout.exercises) {
        console.log('Estou aqui 1')
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: creatingWorkout.name, type: creatingWorkout.type, date: date, exercises: creatingWorkout.exercises }))
      } else {
        console.log('Estou aqui 2')
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: creatingWorkout.name, type: creatingWorkout.type, date: date }))
      }
  
      if (btnType === 'previous') return previous();
  
      if (!date) return toast.error('Preencha a data');
  
      next();
    } else {
      if (creatingWorkout.exercises) {
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: creatingWorkout.name, type: creatingWorkout.type, days: selectedDays, exercises: creatingWorkout.exercises }))
      } else {
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: creatingWorkout.name, type: creatingWorkout.type, days: selectedDays }))
      }

      if (btnType === 'previous') return previous();

      if (selectedDays.length < 1) return toast.error('Escolha pelo menos um dia');

      next();
  
    }
  };

  useEffect(() => {
    if (creatingWorkout.date) {
      setDate(creatingWorkout.date)
    }

    return () => setDate(''); 
  }, []);

  return (
    <>
      <div className="Margin-pages">
        {creatingWorkout.type === 'fixed' ? (
          <>
            <Title
              Title={"Qual o dia da semana você deseja treinar este treino?"}
            />
            <form
              className="mt-11 font-Poppins flex justify-center"
            >
              <label className="flex flex-col">
                <span className="name-Span mb-3">Selecione os dia(s)</span>
                <div className="flex space-x-2">
                  {daysOfWeek.map((day) => (
                    <button
                      key={day.label}
                      type="button"
                      className={`Checkbox ${selectedDays.includes(day.day)
                        ? "bg-Laranja text-white"
                        : ""
                        }`}
                      onClick={() => toggleDaySelection(day.day)}
                    >
                      <p>{day.text}</p>
                    </button>
                  ))}
                </div>
              </label>
            </form>
          </>
        ) : (
          <>
            <Title Title={"Qual dia  você deseja treinar este treino?"} />
            <form className="mt-11 font-Poppins flex justify-center px-20">
              <label>
                <span className="name-Span">Selecione a data: *</span>
                <input type="date" required className="inputs-fullScreen"
                  onChange={(e) => handleChangeDate(e)}
                  value={date}
                />
              </label>
            </form>
          </>
        )}
        <div className=" flex justify-center items-center mt-14">
          <div
            onClick={(e, btnType = 'previous') => handleLeaveComponent(e, btnType)}>
            <BtnGrey name={"Anterior"}  />
          </div>
          <div
            onClick={(e, btnType = 'next') => handleLeaveComponent(e, btnType)}>
            <BtnOrange name="Próximo"  />
          </div>
        </div>
      </div>
    </>
  );
};

export default Week;
