import { useDispatch, useSelector } from "react-redux";

import BtnTypeTraining from "../GlobalsButtons/BtnTypeTraining";
import Title from "./Title/Title";
import * as workoutActions from '../../store/modules/workout/actions';


const TypeTrainig = ({ next, page }) => {
  const dispatch = useDispatch();

  const handleClickType = (e, type) => {
    dispatch(workoutActions.setCurrentCreatingWorkout({type: type}))
  }

  return (
    <div className="mx-4">
      <Title Title={"Escolha o tipo de Treino ideal para você"} />
      <div className="mt-11">
        <div
          onClick={(e, type = 'fixed') => handleClickType(e, type)}
        >
          <BtnTypeTraining
            next={next}
            Title={"Fixo"}
            subTitle={
              "Treino diário para todas as semanas."
            }
          />
        </div>
        <div
          onClick={(e, type = 'unique') => handleClickType(e, type)}
        >
          <BtnTypeTraining
            next={next}
            Title={"Único"}
            subTitle={"Treino específico para treinar uma única vez"}
          />
        </div>
      </div>
    </div>
  );
};

export default TypeTrainig;
