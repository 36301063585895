import { TbAlertTriangleFilled } from "react-icons/tb";

const DeleteExerciseAlert = ({ isOpen, onClose }) => {
  if (!isOpen) return null;


  const handleClickDelete = () => {
    let deleteExercise = true;

    onClose(deleteExercise)
  }

  const handleClickCancel = () => {
    let deleteExercise = false;

    onClose(deleteExercise)
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content text-white">
        <TbAlertTriangleFilled size={130} className="text-LaranjaClaro" />
        <div className="flex flex-col text-center mt-2 mb-11">
          <h2 className="font-bold text-2xl mb-2">Exclusão do exercício!</h2>
          <p className="text-lg">Deseja excluir o exercício?</p>
        </div>
        <div className="flex flex-row items-end">
          <button
            onClick={handleClickCancel}
            className="effectButtons cursor-pointer bg-IconsColor hover:bg-gray-700 active:bg-gray-800 py-2 px-3 mx-2 rounded"
          >
            Cancelar
          </button>
          <button className="effectButtons cursor-pointer font-bold bg-red-700 hover:bg-red-800 active:bg-red-900 py-2 px-6 mx-2 rounded"
          onClick={handleClickDelete}
          >
            Excluir
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteExerciseAlert;
