import NavBar from "../components/NavBar/NavBar";
import Unique from "../components/MyWorkouts/Unique";
import Fixed from "../components/MyWorkouts/Fixed";
import TypeTraining from "../components/MyWorkouts/TypeTraining";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as workoutActions from "../store/modules/workout/actions";

const MyWorkouts = () => {
  const dispatch = useDispatch();
  const [creationPage, setCreationPage] = useState(0);
  const myWorkoutsType = useSelector((state) => state.workout.myWorkoutsType);

  const handleNext = () => {
    setCreationPage((prev) => prev + 1);
  };
  const handlePrevious = () => {
    setCreationPage((prev) => prev - 1);
  };

  const handleLeaveComponent = () => {
    dispatch(workoutActions.setMyWorkoutsType(null));
  };

  useEffect(() => {
    return () => {
      handleLeaveComponent();
    };
  }, []);

  return (
    <>
      <NavBar />
      <div className="container-reponsive">
        {creationPage === 0 ? (
          <div className=" flex flex-col items-center justify-start  font-Poppins">
            <h1 className="Title-pages">Meus treinos</h1>
          </div>
        ) : (
          ""
        )}
        {creationPage === 0 && (
          <TypeTraining
            next={handleNext}
            previou={handlePrevious}
            page={"MyWorkout"}
          />
        )}
        {myWorkoutsType === "unique" && <Unique />}
        {myWorkoutsType === "fixed" && <Fixed />}
      </div>
    </>
  );
};

export default MyWorkouts;
