import React from "react";
import { useDispatch, useSelector } from "react-redux";

import BtnTypeTraining from "../GlobalsButtons/BtnTypeTraining";

const TypeReuse = ({next}) => {
  return (
    <>
      <div className="px-4">
          <BtnTypeTraining
            next={(e) => next(e, 2)}
            Title={"Novo treino"}
            subTitle={"Crie um treino personalizado do zero."}
          />
        <BtnTypeTraining
          next={next}
          Title={"Reutilizar treino"}
          subTitle={"Reutilize um treino existente."}
        />
      </div>
    </>
  );
};

export default TypeReuse;
