export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_PAST_PAGE = 'SET_PAST_PAGE';
export const SET_NEW_PROFILE_PICTURE = 'SET_NEW_PROFILE_PICTURE';
export const UPDATE_USER = 'UPDATE_USER';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_UPDATED_SUCCESS = 'REGISTER_UPDATED_SUCCESS';
export const REGISTER_CREATED_SUCCESS = 'REGISTER_CREATED_SUCCESS';

export const VERIFY_REGISTER_CODE_REQUEST = 'VERIFY_REGISTER_CODE_REQUEST';

export const VERIFY_RECOVERY_CODE_REQUEST = 'VERIFY_RECOVERY_CODE_REQUEST';
export const VERIFY_RECOVERY_CODE_SUCCESS = 'VERIFY_RECOVERY_CODE_SUCCESS';
export const VERIFY_RECOVERY_CODE_FAILURE = 'VERIFY_RECOVERY_CODE_FAILURE';

export const PASSWORD_UDDATE_REQUEST = 'PASSWORD_UDDATE_REQUEST';
export const PASSWORD_UDDATED_SUCCESS = 'PASSWORD_UDDATED_SUCCESS';
export const PASSWORD_UDDATED_FAILURE = 'PASSWORD_UDDATED_FAILURE' 

export const RESENT_REGISTER_CODE = 'RESENT_REGISTER_CODE';
export const SEND_RECOVERY_CODE = 'SEND_RECOVERY_CODE';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const SEND_CODE_FAILURE = 'SEND_CODE_FAILURE';

export const PERSIST_REHYDRATE = 'persist/REHYDRATE';

export const SET_CURRENT_EDITING_EXERCISE = 'SET_CURRENT_EDITING_EXERCISE'; 
export const SET_CURRENT_CREATING_WORKOUT = 'SET_CURRENT_CREATING_WORKOUT'; 
export const SET_MY_WORKOUTS_TYPE = 'SET_MY_WORKOUTS_TYPE';
export const SET_USER_WORKOUTS = 'SET_USER_WORKOUTS'; 

export const CLICKED_NEW_EXERCISE = 'CLICKED_NEW_EXERCISE';

