import NavBar from "../components/NavBar/NavBar"
import Error from "../assets/Error404.svg"

const Error404 = () => {
  return (
    <>
    <NavBar />
    <div className=" flex flex-col my-16 items-center justify-center text-white font-bold text-2xl ">
        <h1 className="py-5">Parece que algo deu errado!</h1>
        <img src={Error} alt="Error404" />
    </div>
    </>
  )
}

export default Error404