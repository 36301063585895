import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/NavBar/NavBar";
import ConfirmCodeHeader from "../components/Header/ConfirmCodeHeader";
import Loading from "../components/Loading/index";
import { toast } from "react-toastify";

import BtnOrange from "../components/GlobalsButtons/BtnOrange";

import * as authActions from "../store/modules/auth/actions";

const NewPassword = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.user.email);
  const recoveryToken = useSelector((state) => state.auth.recoveryToken);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [password, setPassword] = useState("");
  const [repeatedPassword, setrepeatedPassword] = useState();

  const handleClickSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8 || password.length > 30)
      return toast.error("Senha deve ter entre 8 e e 30 caracteres");

    if (password !== repeatedPassword)
      return toast.error("Senhas devem ser iguais");

    dispatch(
      authActions.passwordUpdateRequest({
        email,
        password,
        repeatedPassword,
        recoveryToken,
      })
    );
  };

  return (
    <>
      <NavBar />
      <div className="container-reponsive">
        <Loading isLoading={isLoading} />
        <ConfirmCodeHeader
          title="Olá"
          subTitle="Insira sua nova senha"
        />
        <form className="flex flex-col font-Poppins mt-9 mx-16">
          <label>
            <span className="font-Poppins text-white text-lg">Nova senha:</span>
            <input
              type="password"
              required
              className="inputs-fullScreen mb-5"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label>
            <span className="font-Poppins text-white text-lg">
              Repetir senha:
            </span>
            <input
              type="password"
              required
              className="inputs-fullScreen mb-5"
              onChange={(e) => setrepeatedPassword(e.target.value)}
            />
          </label>
          <div className="flex justify-center items-center mt-14">
            {/* Botão para enviar o login */}
            <BtnOrange
              name="Alterar"
              type="submit"
              next={(e) => handleClickSubmit(e)}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default NewPassword;
