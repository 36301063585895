import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaXmark } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";

import DeleteExerciseAlert from "../../components/Alert/DeleteExercise";

const ViewTraining = ({ isOpen, onClose }) => {
  const workout = useSelector(state => state.workout.creatingWorkout);
  const [deleteExerciseAlert, setDeleteExerciseAlert] = useState(false);
  const [indexExerciseToDelete, setIndexExerciseToDelete ] = useState(false);
  
  const handleExitViewTraining = () => {
    onClose();
  };

  const handleClickDeleteExercise = (index) => {
    setDeleteExerciseAlert(true)
    setIndexExerciseToDelete(index);
  }

  const handleCloseDeleteExerciseAlert = (deleteExercise) => {
    setDeleteExerciseAlert(false);

    if (!deleteExercise) return; 

    workout.exercises.splice(indexExerciseToDelete, 1);
    setIndexExerciseToDelete(false);
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="flex w-full items-center justify-center relative mb-11">
          <div className="flex-col text-center items-center text-white font-Poppins">
            <h1 className="font-bold text-2xl">{workout.name}</h1>
            <p className="font-extralight text-sm text-IconsColor">  {new Date(workout.date + 'T00:00:00').toLocaleDateString('pt-BR')}
            </p>
          </div>
          <FaXmark 
            size={35} 
            className=" cursor-pointer absolute right-0 -top-1 text-IconsColor" 
            onClick={handleExitViewTraining} 
          />
        </div>

        
        <div className="overflow-y-scroll max-h-96 scrollbar-custom w-full px-2">
        <hr className="w-full border-IconsColor" />
        {workout.exercises.map((exercise, exerciseIndex) => (
          <div className="w-full" key={exerciseIndex}>
            <div className=" font-Poppins flex flex-row justify-between">
              <div className="flex flex-col justify-center">
                <p className="font-Poppins text-white text-base">
                  {exercise.name}
                </p>
                <p className="font-Poppins text-Laranja text-base">
                  {exercise.muscle}
                </p>
                <FaTrash
                  size={20}
                  className="cursor-pointer text-red-700 my-2"
                  onClick={e => handleClickDeleteExercise(exerciseIndex)}
                />
              </div>
              <div className="text-white">
                <table>
                  <thead className="text-sm">
                    <tr>
                      <th className="px-2 p-1 font-extralight">Série</th>
                      <th className="px-2 p-1 font-extralight">Reps</th>
                      <th className="px-2 p-1 font-extralight">Peso</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exercise.series.map((serie, serieIndex) => ( // Correção aqui
                      <tr key={serieIndex} className="text-center text-sm">
                        <td>{serie.serie}</td>
                        <td>{serie.repetitions}</td>
                        <td>{serie.weight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
              <hr className="w-full border-IconsColor my-1" />
          </div>
        ))}
        </div>
      </div>
      <DeleteExerciseAlert isOpen={deleteExerciseAlert} onClose={(deleteExercise) => handleCloseDeleteExerciseAlert(deleteExercise)} />
    </div>
  );
  
};

export default ViewTraining;
