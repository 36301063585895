import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const RecoverHeader = () => {
  const dispatch = useDispatch();
  const pastPage = useSelector(state => state.auth.pastPage);
  const user = useSelector(state => state.auth.user.name)
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    if (pastPage === 'profile' || pastPage === 'login') {
      setTitle('Altere sua senha');
      setSubtitle('insira seu email para que possamos te ajudar a alterar sua senha');
    } else {
      setTitle('Confirme seu email');
      setSubtitle('digite o código que enviamos para o seu email');
    }
  }, [])

  return (
    <div>
        <Link to="./login">
          <IoIosArrowBack size={60} className="text-IconsColor absolute"/>
        </Link>
        <div className="flex flex-col items-center font-Poppins text-white mt-7 justify-center mx-14">
        <h1 className="text-xl text-center font-bold">{title}<strong className="text-Laranja"> {pastPage === 'profile' ? (user) : ("")}</strong></h1>
        <p className="text-center text-base tracking-tight">{subtitle}</p>
        </div>
    </div>
  )
}

export default RecoverHeader