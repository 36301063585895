import * as types from '../types' 

export function setCurrentEditingExercise(payload) {
    return {
        type: types.SET_CURRENT_EDITING_EXERCISE,
        payload
    }
}

export function setCurrentCreatingWorkout(payload) {
    return {
        type: types.SET_CURRENT_CREATING_WORKOUT,
        payload
    }
}

export function setMyWorkoutsType(payload) {
    return {
        type: types.SET_MY_WORKOUTS_TYPE,
        payload
    }
}

export function setUserWorkouts(payload) {
    return {
        type: types.SET_USER_WORKOUTS,
        payload
    }
}

export function clickedNewExercise() {
    return {
        type: types.CLICKED_NEW_EXERCISE,
    }
}

