import { FaTrash } from "react-icons/fa";
import { FaFilePen } from "react-icons/fa6";

const ModalSelection = ({ isOpen, onDelete, onEdit }) => {
  if (!isOpen) return null;

  return (
    <div className="flex flex-col w-32 bg-Fundo rounded-lg py-1 px-4 shadow-[-11px_14px_41px_0px_#000] ">
      <button onClick={onEdit} className=" effectButtons cursor-pointer flex flex-row py-1">
        <FaFilePen size={24} className="text-blue-500 hover:text-blue-600 active:text-blue-800" />
        <p className="text-blue-500 text-xl px-2">Editar</p>
      </button>

      <button onClick={onDelete} className=" effectButtons cursor-pointer flex flex-row py-1">
        <FaTrash size={24} className="text-red-700 hover:text-red-800 active:text-red-900 my-2" />
        <p className="text-red-700 text-xl px-2 my-2">Excluir</p>
      </button>
    </div>
  );
};

export default ModalSelection;
