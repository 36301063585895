
// Route
import Personal from "../../assets/PersonalTraining.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";

// Hooks
import { useState, useEffect } from "react";

//Icons
import { FaAngleRight } from "react-icons/fa6";


import axios from '../../services/axios';
import { useSelector } from "react-redux";

const Fixed = () => {

    const userId = useSelector(state => state.auth.user.id);
    const [workout, setWorkout] = useState('');
    
  
    useEffect( () => {
  
      async function getData() {
        try {
          const dataApi = await axios.get(`/workouts/user/${userId}`, userId);
          setWorkout(dataApi.data);
          console.log(dataApi.data)
        } catch (err) {
          console.log(err)
        }
      }
  
      getData();
  
    }, []);
  
    return (
        <>

        {/* Faz a manipulação dos estados  */}
  
        {workout.length > 0 ? (
          <div className=" font-Poppins mx-5">
            <div className=" flex flex-col items-center justify-start  font-Poppins">
              <h1 className="Title-pages">Treinos fixos</h1>
            </div>
            <div className=" my-8 ">
              <p className=" text-LaranjaClaro text-lg p-3">Pendentes:</p>
              <hr className=" w-full bg-IconsColor " />
            </div>
            {workout.map((workout, index) => (
              workout.status == 'Pending' && workout.type == 'fixed' ? 
              <Link to={`workout/${workout._id}`} key={index}>
              <div
                key={index}
                className=" effect border-IconsColor border-solid border-2 p-4 rounded-xl my-3 mx-2 flex flex-row items-center"
              >
                <p className=" flex-1 font-bold text-white text-lg whitespace-nowrap ">
                  {workout.name}
                </p>
                <FaAngleRight size={38} className="text-IconsColor pointer" />
              </div>
            </Link> : false 
            ))}
            <div>
              <p className=" text-white text-lg p-3">Concluido:</p>
              <hr className=" w-full bg-IconsColor " />
            </div>
            {workout.map((workout, index) => (
              workout.status == 'Done' && workout.type == 'fixed' ? 
              <Link to={`workout/${workout._id}`} key={index}>
              <div
                key={index}
                className=" effect border-IconsColor border-solid border-2 p-4 rounded-xl my-3 mx-2 flex flex-row items-center"
              >
                <p className=" flex-1 font-bold text-white text-lg whitespace-nowrap ">
                  {workout.name}
                </p>
                <FaAngleRight size={38} className="text-IconsColor pointer" />
              </div>
            </Link> : false 
            ))}
          </div>
          
        ) 
        
        
        : (
          <div>
            <div className=" flex flex-col items-center justify-start  font-Poppins">
              <h1 className="Title-pages">Treinos fixos</h1>
              <p className=" text-Laranja text-xl my-3 ">
                Você ainda não tem treinos
              </p>
            </div>
            <div className=" flex justify-center items-center my-11 ">
              <img src={Personal} draggable="false" alt="Perosanal Trainer" />
            </div>
            <div className=" flex justify-center items-center ">
              <Link to="/createworkout">
                <button className=" pointer bg-Laranja hover:bg-LaranjaClaro py-3 px-8 text-white font-bold rounded-lg ">
                  Criar treino
                </button>
              </Link>
            </div>
          </div>
        )}
      </>
    )
}

export default Fixed;