import { useDispatch, useSelector } from "react-redux";

import * as workoutActions from '../../store/modules/workout/actions';

import Title from "./Title/Title";
import BtnOrange from "../GlobalsButtons/BtnOrange";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


const NewWorkouts = ({ next }) => {
  const dispatch = useDispatch();
  const creatingWorkout = useSelector(state => state.workout.creatingWorkout)

  const [name, setName] = useState('')

  const handleLeaveComponent = (e) => {
    e.preventDefault()
    
    if (name.length < 3 || name.length > 30) return toast.error('Nome do treino deve ter entre 3 e 30 caracteres')

    if (creatingWorkout.date) {
      if (creatingWorkout.exercises) {
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: name, type: creatingWorkout.type, date: creatingWorkout.date, exercises: creatingWorkout.exercises }))
      } else {
        dispatch(workoutActions.setCurrentCreatingWorkout({ name: name, type: creatingWorkout.type, date: creatingWorkout.date }))
      }
    } else {
      dispatch(workoutActions.setCurrentCreatingWorkout({ name: name, type: creatingWorkout.type }))
    }

    if (creatingWorkout.exercises) {
      dispatch(workoutActions.setCurrentCreatingWorkout({ name: name, type: creatingWorkout.type, date: creatingWorkout.date, exercises: creatingWorkout.exercises }))
    } else {
      dispatch(workoutActions.setCurrentCreatingWorkout({ name: name, type: creatingWorkout.type, date: creatingWorkout.date }))
    }

    
    next();
  }

  useEffect(() => {
    if (creatingWorkout.name) {
      setName(creatingWorkout.name)
    }
  }, []);

  return (
    <>
      <div className="Margin-pages">
        <Title Title={"Qual o nome do seu treino?"} />
        <form className="mt-11 flex flex-col ">
          <label className="mb-11">
            <span className="name-Span">Insira o nome: *</span>
            <input type="text" required className="inputs-fullScreen" id="name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </label>
          <div className="flex items-center justify-center"
            onClick={(e) => handleLeaveComponent(e)}
          >
            <BtnOrange
              name={"Próximo"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default NewWorkouts;
