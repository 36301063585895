import { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../SideBar/Menu';


// Icons
import { FaBars } from "react-icons/fa6";

// Logo
import Logo from '../../assets/Logo.svg'

const NavBar = () => {
  const [menuVisivel, setMenuVisivel] = useState(false);
  const toggleMenu = () => {
    setMenuVisivel(!menuVisivel);
  };
  const closeVisivel = () => {
    setMenuVisivel(false);
  };
  return (
    <>
        <nav className=" flex flex-row bg-NavBarColor px-10 justify-between items-center md:px-20">
            <Link  to="/" className="m-auto"><img src={Logo} alt="Logo" className=' h-32 flex-1' /></Link>
            <FaBars size={30} onClick={toggleMenu} className='  text-IconsColor cursor-pointer ' />
        </nav>
      {menuVisivel && <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30" onClick={closeVisivel}></div>}
      <Menu visible={menuVisivel} />
    </>
  );
}

export default NavBar;
