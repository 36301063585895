import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';

export default reducers => {
    const persistedReducers = persistReducer(
        {
            key: 'TRAINING-LOG',
            storage,
            whitelist: ['auth', 'workout']

        },
        reducers
    );

    return persistedReducers;
}