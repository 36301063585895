import * as types from '../types'
import axios from '../../../services/axios';

const initialState = {
    editingExerciseIndex: null,
    editingExercise: null,
    creatingWorkout: null,
    timesClickedNewExercise: 0,
    myWorkoutsType: null,
    userWorkouts: null,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_CURRENT_EDITING_EXERCISE: {
            const newState = { ...state};
            newState.editingExerciseIndex = action.payload.exerciseIndex; 
            newState.editingExercise = action.payload.exercise; 
            return newState;
        }

        case types.SET_CURRENT_CREATING_WORKOUT: {
            const newState = { ...state};
            newState.creatingWorkout = action.payload;
            return newState;
        }

        case types.SET_MY_WORKOUTS_TYPE: {
            const newState = { ...state};
            newState.myWorkoutsType = action.payload;
            return newState;
        }

        case types.SET_USER_WORKOUTS: {
            const newState = { ...state};
            newState.userWorkouts = action.payload;
            return newState;
        }

        case types.CLICKED_NEW_EXERCISE: {
            const newState = { ...state};
            if (newState.timesClickedNewExercise < 2) {
                newState.timesClickedNewExercise += 1;
            } else {
                newState.timesClickedNewExercise = 1;
            }
            return newState;
        }

        default:
            return state;
    }
}