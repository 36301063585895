import BtnGrey from "../GlobalsButtons/BtnGrey";
import BtnBlue from "../GlobalsButtons/BtnBlue";
import BtnOrange from "../GlobalsButtons/BtnOrange";
import Title from "./Title/Title";
import { LuGripHorizontal } from "react-icons/lu";
import NewExercise from "../NewExercise/NewExercise";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as workoutActions from "../../store/modules/workout/actions";
import { toast } from "react-toastify";
import axios from "../../services/axios";
import history from "../../services/history";
import Draggable from "react-draggable";
import { FaTrash } from "react-icons/fa";

import DeleteExerciseAlert from "../../components/Alert/DeleteExercise";

const Create = ({ next, previous }) => {
  const dispatch = useDispatch();
  const creatingWorkout = useSelector((state) => state.workout.creatingWorkout);
  const editingExerciseIndex = useSelector(
    (state) => state.workout.editingExerciseIndex
  );
  const workout = useSelector((state) => state.workout.creatingWorkout);
  const [indexExerciseToDelete, setIndexExerciseToDelete] = useState(false);
  const [showNewExercise, setShowNewExercise] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [workoutCreate, setWorkout] = useState({});
  const [viewTraining, setViewTraining] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [deleteExerciseAlert, setDeleteExerciseAlert] = useState(false);

  const handleClickDeleteExercise = (index) => {
    setDeleteExerciseAlert(true)
    setIndexExerciseToDelete(index);
  }

  const handleCloseDeleteExerciseAlert = (deleteExercise) => {
    setDeleteExerciseAlert(false);

    if (!deleteExercise) return; 

    exercises.splice(indexExerciseToDelete, 1);
    setIndexExerciseToDelete(false);
  }

  const handleClickNewExercise = (e) => {
    dispatch(workoutActions.clickedNewExercise());
    setShowNewExercise(true);
  };

  const handleCloseNewExercise = (newExercise) => {
    setShowNewExercise(false);

    if (!newExercise) return;

    exercises.push(newExercise);
  };

  const handleClickSubmit = async (e) => {
    e.preventDefault();
    if (exercises.length === 0)
      return toast.error("Adicione pelo menos um exercício");

    workoutCreate.name = creatingWorkout.name;
    if (creatingWorkout.date) workoutCreate.date = creatingWorkout.date;
    if (creatingWorkout.days) workoutCreate.days = creatingWorkout.days;
    workoutCreate.type = creatingWorkout.type;
    workoutCreate.exercises = exercises;

    console.log(workoutCreate);

    try {
      const response = await axios.post("/workouts", workoutCreate);
      console.log(response);
      console.log(response.data.workout.id, "eieiie");
      toast.success("Treino criado com sucesso");
      history.push(`/workout/${response.data.workout.id}`);
    } catch (err) {
      toast.error("Erro ao criar treino");
    }
  };

  useEffect(() => {
    if (creatingWorkout.exercises) {
      setExercises(creatingWorkout.exercises);
    }

    return () => setExercises([]);
  }, []);

  const handleLeaveComponent = () => {
    dispatch(
      workoutActions.setCurrentCreatingWorkout({
        name: creatingWorkout.name,
        type: creatingWorkout.type,
        date: creatingWorkout.date,
        exercises: exercises,
      })
    );
    previous();
  };

  const save = () => {
    return;
  };

  return (
    <div className=" h-full flex flex-col items-center">
      <Title Title={"Adicione mais exercicios ao seu treino!"} />
      <div className="mt-8 flex flex-col items-center z-10">
        <div onClick={handleClickNewExercise}>
          <BtnOrange name={"Adicionar"} />
        </div>
        <div onClick={() => handleLeaveComponent()}>
          <BtnGrey name={"Anterior"} />
        </div>
      </div>

      <Title Title={"Crie seu treino!"} />
      <div className="mt-5 z-10" onClick={handleClickSubmit}>
        <BtnBlue name={"Criar"} save={save} />
      </div>
      <div className="absolute inset-x-0 bottom-0 flex justify-center h-96 overflow-hidden">
        <Draggable
          axis="y"
          bounds={{ top: 5, bottom: 300 }}
          defaultPosition={{ x: 0, y: 300 }}
          onStart={() => setIsDragging(true)}
          onStop={() => setIsDragging(false)}
        >
          <div className=" absolute h-full w-full py-1 bg-Fundo text-white shadow-shadow-main-black rounded-t-3xl flex flex-col items-center z-30">
            <LuGripHorizontal
              size={35}
              className="text-IconsColor cursor-move"
            />
            <h1 className="font-bold text-xl">View treino</h1>
            <div className=" px-5 mt-8 w-full max-h-80 overflow-y-scroll scrollbar-custom">
              {exercises && exercises.length > 0 ? (
                exercises.map((exercise, exerciseIndex) => (
                  <div className="w-full" key={exerciseIndex}>
                    <div className=" font-Poppins flex flex-row justify-between">
                      <div className="flex flex-col justify-center">
                        <p className="font-Poppins text-white text-base">
                          {exercise.name}
                        </p>
                        <p className="font-Poppins text-Laranja text-base">
                          {exercise.muscle}
                        </p>
                        <FaTrash
                          size={20}
                          className="cursor-pointer text-red-700 my-2"
                          onClick={(e) =>
                            handleClickDeleteExercise(exerciseIndex)
                          }
                        />
                      </div>
                      <div className="text-white">
                        <table>
                          <thead className="text-sm">
                            <tr>
                              <th className="px-2 p-1 font-extralight">
                                Série
                              </th>
                              <th className="px-2 p-1 font-extralight">Reps</th>
                              <th className="px-2 p-1 font-extralight">Peso</th>
                            </tr>
                          </thead>
                          <tbody>
                            {exercise.series.map((serie, serieIndex) => (
                              <tr
                                key={serieIndex}
                                className="text-center text-sm"
                              >
                                <td>{serie.serie}</td>
                                <td>{serie.repetitions}</td>
                                <td>{serie.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr className="w-full border-IconsColor my-1" />
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center my-24 font-Poppins">
                  <p className="text-center">
                    Você ainda não adicionou nenhum exercício.
                  </p>
                </div>
              )}
            </div>
          </div>
        </Draggable>
      </div>

      {/* <p
        className="cursor-pointer text-blue-400 hover:text-blue-500 active:text-blue-950 inline-block relative top-3"
        onClick={handleClickViewWorkout}
      >
        View treino
      </p> */}
      <NewExercise
        isOpen={showNewExercise}
        onClose={(newExercise = false) => handleCloseNewExercise(newExercise)}
      />
      <DeleteExerciseAlert isOpen={deleteExerciseAlert} onClose={(deleteExercise) => handleCloseDeleteExerciseAlert(deleteExercise)} />
    </div>
  );
};

export default Create;
