import styled, { createGlobalStyle } from "styled-components";
import { primaryColor, primaryDarkColor, sucessColor, infoColor, warningColor, errorColor } from "../config/colors";
import 'react-toastify/dist/ReactToastify.css';



export default createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
        outline: none;
        box-sizing: border-box;
    }

    body .Toastify .Toastify__toast-container .Toastify__toast--success {
  background: ${sucessColor};
  color: white;
}

    .Toastify__progress-bar--success {
  background: white;
}
 
 
 
body .Toastify .Toastify__toast-container .Toastify__toast--error {
  background: ${errorColor};
  color: white;
}

.Toastify__progress-bar--error {
  background: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.select-arrow {
    -webkit-appearance: none;  
    -moz-appearance: none;   
    appearance: none;        
    background-image: url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0px center;
    background-size: 50px 50px;
    padding-right: 0rem; 
}

`;
