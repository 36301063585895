import NavBar from "../components/NavBar/NavBar";
import ConfirmCodeHeader from "../components/Header/ConfirmCodeHeader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../store/modules/auth/actions";
import { useRef } from "react";
import Loading from "../components/Loading/index";
import BtnOrange from "../components/GlobalsButtons/BtnOrange";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.user.email);
  const name = useSelector((state) => state.auth.user.name);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const codeType = useSelector((state) => state.auth.codeType);

  const inputRefs = useRef([]);

  const handleClickValidate = (e) => {
    e.preventDefault();

    const code = inputRefs.current.map((input) => input.value).join("");

    if (code.length < 6) {
      return toast.error("Preencha todos os campos");
    }

    if (code.length > 6) {
      return toast.error("Coloque apenas um número em cada campo");
    }

    if (codeType === "Creation") {
      dispatch(authActions.verifyRegisterCodeRequest({ code, email, name }));
    }

    if (codeType === "Recovery") {
      dispatch(authActions.verifyRecoveryCodeRequest({ code, email }));
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const key = e.nativeEvent.inputType;

    // Se o valor for preenchido, vai para o próximo input
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Se o usuário apagar o valor, vai para o input anterior
    if (key === "deleteContentBackward" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClickResentCode = (e) => {
    if (codeType === "Creation") {
      dispatch(authActions.resentRegisterCode({ email }));
    }

    if (codeType === "Recovery") {
      dispatch(authActions.sendRecoveryCode({ email, isResend: true }));
    }
  };

  return (
    <>
      <NavBar />
      <div className="container-reponsive">
        <Loading isLoading={isLoading} />
        <ConfirmCodeHeader
          title="Confirme seu email"
          subTitle="Digite o código que enviamos para o seu email"
        />
        <form className="flex flex-col font-Poppins mt-9 mx-16">
          <div className="flex flex-row">
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                maxLength={1}
                required
                className="inputs-fullScreen mx-1 text-center"
                ref={(el) => (inputRefs.current[index] = el)} // Atribuir a referência
                onChange={(e) => handleInputChange(e, index)} // Evento de mudança
                onKeyDown={(e) => {
                  if (
                    e.key === "Backspace" &&
                    inputRefs.current[index].value === "" &&
                    index > 0
                  ) {
                    inputRefs.current[index - 1].focus(); // Retroceder ao anterior se backspace
                  }
                }}
              />
            ))}
          </div>
          <p
            className="text-blue-400 mt-3 hover:text-blue-500 active:text-blue-950 pointer cursor-pointer"
            onClick={(e) => handleClickResentCode(e)}
          >
            Reenviar código
          </p>
          <div className="flex justify-center items-center mt-14">
            <BtnOrange 
            name={"Válidar"}
            next={handleClickValidate} />
          </div>
        </form>
      </div>
    </>
  );
};

export default ConfirmEmail;
