import { FaAngleRight } from "react-icons/fa6";

const TrainingButtons = ({ name }) => {
  return (
    <button className="effect flex flex-row items-center justify-between pointer px-3 border-b-2 border-CinzaClaro w-full text-white mb-7">
      <p className="py-3 font-Poppins font-bold text-xl">{name}</p>
      <FaAngleRight size={38} className="text-IconsColor" />
    </button>
  );
};

export default TrainingButtons;
