/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";


import NavBar from "../components/NavBar/NavBar";
import BtnBlue from "../components/GlobalsButtons/BtnBlue";
import SelectAvatar from "../components/Modal/SelectAvatar";

import { MdEdit } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

import * as authActions from '../store/modules/auth/actions';
import { useDispatch, useSelector } from "react-redux";
import history from '../services/history';
import axios from '../services/axios';
import { toast } from "react-toastify";


const Profile = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user.id);
  const email = useSelector(state => state.auth.user.email);
  const name = useSelector(state => state.auth.user.name);
  const [newName, setNewName] = useState(name);
  const [profilePicture, setProfilePicture] = useState(useSelector(state => state.auth.user.profilePicture));
  const [editProfile, setEditProfile] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);

  const handleClickEdit = () => {
    setEditProfile(true);
  }

  useEffect(() => {
    if (profilePicture) return;
    setProfilePicture('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==')
  }, []);

  const handleClickSave = async () => {

    if (name === newName) return  setEditProfile(false);;

    if (newName.length < 2 || newName.length > 25)
      return toast.error("Nome deve ter entre 2 e 25 caracteres");

    try {
      await axios.put(`/users/${userId}`, {name: newName});
      dispatch(authActions.updateUser({name: newName}))
      toast.success('Nome atualizado com sucesso')
      setEditProfile(false);
    } catch (err) {
      toast.error('Erro ao atualizar nome')
      console.log(err)
      setEditProfile(false);
    }
  }

  const handleClickEditAvatar = () => {
    setEditAvatar(true);
  }

  const handleClickCloseSelectAvatar = (picture) => {
    setProfilePicture(picture)
    setEditAvatar(false);
    setEditProfile(false);
  }

  const handleClickReset = () => {
    dispatch(authActions.setPastPage('profile'));
    history.push('/recoverpassword')
  }

  return (
    <>
      <NavBar />
      {editProfile === true ? (
        <div className="container-reponsive flex flex-col items-center text-white font-Poppins">
          <div className="mt-11 flex flex-col items-center w-5/6">
             <div className="flex justify-center items-center w-28 h-28 mb-4 rounded-full border-4 border-IconsColor">
              <MdEdit size={40} className="text-IconsColor cursor-pointer absolute z-10" onClick={handleClickEditAvatar} />
                <img src={profilePicture} draggable={false} className="rounded-full opacity-30" />
              </div> 
              
            <div className="flex w-full items-center justify-center relative px-16">
              <input className="inputs-fullScreen text-center" placeholder="Digite seu Nome" value={newName} onChange={e => setNewName(e.target.value)}/>
            </div>
            <hr className="w-full my-5 border-IconsColor" />
            <div className="mb-20">
              <div className="flex">
                <p className="text-gray-bold mr-3 pl-2">Email:</p>
                <p>{email}</p>
              </div>
              <div className="flex">
                <p className="text-gray-bold mr-3">Senha:</p>
                <p className="flex-1">***********</p>

                <p className="effect ml-3 font-bold text-blue-600 cursor-pointer" 
                onClick={handleClickReset}
                >redefinir</p>

              </div>
            </div>
            <BtnBlue name="Salvar alterações" save={handleClickSave} />
          </div>
          <SelectAvatar onClose={(picture) => handleClickCloseSelectAvatar(picture)}  isOpen={editAvatar} />
        </div>
      ) : (
        <div className="container-reponsive flex flex-col items-center text-white font-Poppins">
          <div className="mt-11 flex flex-col items-center w-5/6">
               <img src={profilePicture} className="bg-black w-28 h-28 mb-4 rounded-full border-4 border-IconsColor"/>
            <div className="flex w-full items-center justify-center relative">
              <p className="text-xl font-bold">{name}</p>
              <FaRegEdit size={25} className="mr-2 text-IconsColor absolute right-0 cursor-pointer"
                onClick={handleClickEdit} />
            </div>
            <hr className="w-full my-5 border-IconsColor" />
            <div className="mb-20">
              <div className="flex">
                <p className="text-gray-bold mr-3 pl-2">Email:</p>
                <p>{email}</p>
              </div>
              <div className="flex">
                <p className="text-gray-bold mr-3">Senha:</p>
                <p>***********</p>
              </div>
            </div>
            <button className="bg-red-700 px-7 py-2 m-2 font-Poppins font-extrabold text-white text-lg rounded-sm shadow-shadow-main-black active:bg-black active:shadow-shadow-main-black active:text-IconsColor effect">Sair da Conta</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
