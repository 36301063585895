import { FaAngleRight } from "react-icons/fa6";

const BtnTypeTraining = ({Title, subTitle, next}) => {
  return (
    <button onClick={next} className="effect cursor-pointer flex flex-row w-full items-center rounded-md bg-Cinza active:bg-black shadow-shadow-main-black p-2 my-3 ">
      <div className="text-left flex-1">
        <h1  className="text-white font-bold text-2xl">{Title}</h1>
        <p className="text-IconsColor leading-none mb-1 cursor-pointer">{subTitle}</p>
      </div>
      <div>
        <FaAngleRight size={38} className="text-IconsColor" />
      </div>
    </button>
  );
};

export default BtnTypeTraining;
