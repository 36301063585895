import * as authActions from '../../store/modules/auth/actions';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
const profilePictures = [
    'https://i.imgur.com/CEzTvsn.jpeg',
    'https://i.imgur.com/KbRpALi.jpeg',
    'https://i.imgur.com/BvEY6Ug.jpeg',
    'https://i.imgur.com/UfaYWIq.jpeg',
    'https://i.imgur.com/DG78Lkc.jpeg',
    'https://i.imgur.com/t7iCpJ0.jpeg',
    'https://i.imgur.com/OYlJvOP.jpeg',
    'https://i.imgur.com/APsMqPf.jpeg',
    'https://i.imgur.com/IgMa2Wx.jpeg',
    'https://i.imgur.com/6GAlGmU.jpeg',
    'https://i.imgur.com/oi6x4yz.jpeg',
    'https://i.imgur.com/nQ9FByh.jpeg',
    'https://i.imgur.com/viekuxB.jpeg',
    'https://i.imgur.com/yJigTf5.jpeg',
    'https://i.imgur.com/VSrIiQw.jpeg',
    'https://i.imgur.com/ic3pmg6.jpeg',
    'https://i.imgur.com/hZxQps4.jpeg',
    'https://i.imgur.com/wQDkoxo.png',
    'https://i.imgur.com/U9iwERA.jpeg',
    'https://i.imgur.com/jiTYbAI.jpeg',
    'https://i.imgur.com/WdhpLuL.jpeg',
 
 ] 



const ProfileGrid = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(authActions.setNewProfilePicture(null));
      }, [])
    
    const handleclickPicture = (e) => {
        dispatch(authActions.setNewProfilePicture(e.target.src))
    }

    const [focusedImage, setFocusedImage] = useState(null);

    const handleFocus = (index) => {
        setFocusedImage(index);
    };

    return (
        <div className="grid grid-cols-2 gap-4 p-4 cursor-pointer" onClick={(e) => handleclickPicture(e)}>
            {profilePictures.map((picture, index) => (
                <div>
                <img
                    key={index}
                    src={picture}
                    alt={`Profile Avatar ${index + 1}`}
                    // className="effect w-full h-full object-cover rounded-full border-4 text-center border-IconsColor shadow-md focus:text-black" 
                    onClick={() => handleFocus(index)}
                    className={`effect w-full h-full object-cover rounded-full border-4 text-center border-IconsColor shadow-md cursor-pointer ${
                        focusedImage === index ? 'border-4 border-white' : ''
                    }`}
                />
                </div>
            ))}
        </div>
    );
};

export default ProfileGrid;